import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Projects" description="Work Projects and Open Source Contributions explained with technical details and links to source code." breadcrumbListItems={[{
      name: `Projects`,
      url: `/projects`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "projects"
    }}>{`Projects`}</h1>
    <h2 {...{
      "id": "mechanical-keyboard-simulator"
    }}><em parentName="h2">{`Mechanical Keyboard Simulator`}</em></h2>
    <p><a parentName="p" {...{
        "href": "https://kbs.im"
      }}>{`kbs.im`}</a>{` is a mechanical keyboard typing simulator built on React + Redux. It features a layout parser, custom keyswitch sounds sampled from the internet + my own keyboards, and custom-made keys brought to life with some CSS tricks. Most of the work nowadays is maintenance work - incorporating some features from the community (there’s a `}<a parentName="p" {...{
        "href": "https://discord.gg/gSZXeydrQR"
      }}>{`discord`}</a>{`) and articles some simple tests in Jest. You can find it on `}<a parentName="p" {...{
        "href": "https://github.com/tplai/kbsim"
      }}>{`GitHub`}</a>{` or live at `}<a parentName="p" {...{
        "href": "https://kbs.im"
      }}>{`kbs.im`}</a>{`.`}</p>
    <h2 {...{
      "id": "perfect-posture"
    }}><em parentName="h2">{`Perfect Posture`}</em></h2>
    <p><a parentName="p" {...{
        "href": "https://pstr.fyi"
      }}>{`pstr.fyi`}</a>{` is a sitting posture analyzer built with Tensorflow and React. It parses a person sitting down from a profile perspective, then analyzes the posture of the hips, back, knees, and neck. After, it produces a score from 0-10 and recommends feedback for better sitting posture. It’s pretty barebones, but there’s nothing quite like it out there just yet. The source code lives on `}<a parentName="p" {...{
        "href": "https://github.com/tplai/pposture"
      }}>{`GitHub`}</a>{` and live @ `}<a parentName="p" {...{
        "href": "https://pstr.fyi"
      }}>{`pstr.fyi`}</a></p>
    <h2 {...{
      "id": "personal-site-tlaiio"
    }}><em parentName="h2">{`Personal site (tlai.io)`}</em></h2>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`tlai.io`}</a>{` is this quaint site for whenever I feel writing about things. It’s built with Gatsby and MDX - Gatsby rocks for small static sites and MDX is sweet for powering the blog and wiki pages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      